var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-reservation-modify-insurance-create-cover-note",
      "title": _vm.$t('reservation[\'Create cover note\']'),
      "title-class": "text-airline font-medium-4 font-weight-bolder mb-0",
      "body-class": "px-75",
      "footer-class": "px-50",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary px-1 px-sm-2"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-1 px-sm-2",
          attrs: {
            "variant": "info",
            "disabled": !_vm.isConfirm,
            "pill": ""
          },
          on: {
            "click": _vm.handleAddInsuranceServices
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Create cover note\']')) + " ")])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.dataInsuranceDraft,
      "spinner-variant": 'info'
    }
  }, [_vm.dataInsuranceDraft ? _c('div', [_c('b-card', {
    staticClass: "border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-25 px-25 px-md-1",
      "body-class": "p-75 px-md-2"
    }
  }, [_c('b-row', {
    staticClass: "mb-25"
  }, [_c('b-col', {
    attrs: {
      "cols": "5",
      "lg": "3"
    }
  }, [_vm._v("Tên gói:")]), _c('b-col', {
    staticClass: "fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceDraft.planName || '') + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5",
      "lg": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'status\']')) + ": ")]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": _vm.resolveVariantInsuranceServices(_vm.dataInsuranceDraft.status)
    }
  }, [_vm._v(" " + _vm._s(_vm.resolveInsuranceSpecialServicesName(_vm.dataInsuranceDraft.status)) + " ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5",
      "lg": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'bookingCode\']')) + ": ")]), _c('b-col', {
    staticClass: "fw-700 text-airline",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceDraft.bookingCode) + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5",
      "lg": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t('reservation[\'itinerary\']')) + ":")]), _c('b-col', {
    staticClass: "text-airline fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.resolveInsuranceItinerariesName(_vm.dataInsuranceDraft.flightJourney, 'journeys')) + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5",
      "lg": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t('reservation[\'Start time\']')) + ":")]), _c('b-col', {
    staticClass: "text-airline fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.dataInsuranceDraft.flightJourney[0].boardingTime, 'UTC').dateTime) + " ")])], 1)], 1), _c('b-card', {
    staticClass: "border",
    attrs: {
      "body-class": "p-75"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Phone",
      "rules": "required|phoneNumber"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "phone"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Contact phone number\']')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "id": "phone",
            "placeholder": "Vui lòng nhập",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "formatter": _vm.trimInput
          },
          model: {
            value: _vm.formData.phoneNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "phoneNumber", $$v);
            },
            expression: "formData.phoneNumber"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 3656261545)
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "email"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Contact email\']')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "email",
            "placeholder": _vm.$t('reservation[\'placeholderInput\']'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "formatter": _vm.trimInput
          },
          model: {
            value: _vm.formData.emailAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "emailAddress", $$v);
            },
            expression: "formData.emailAddress"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 3222100052)
  })], 1)], 1)], 1), _c('b-card', {
    staticClass: "border",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "py-25 px-25 px-md-1",
      "body-class": "p-75"
    }
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Customer name used\']')) + " ")];
      },
      proxy: true
    }], null, false, 1666352477)
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.dataInsuranceDraft.passengerName,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('reservation[\'DoB\']'),
      "rules": "required",
      "vid": "passenger-birthday"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('reservation[\'DoB\']')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.formData.birthday,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "birthday", $$v);
            },
            expression: "formData.birthday"
          }
        }), _c('div', {
          staticClass: "form-control p-0",
          class: validationContext.errors[0] ? 'is-invalid' : ''
        }, [_c('flat-pickr', {
          staticClass: "form-control px-50 py-0",
          attrs: {
            "id": "passenger-birthday",
            "name": _vm.$t('flight.dob'),
            "config": Object.assign({}, _vm.configFlatPickr, {
              altInputClass: 'form-control input px-50 py-0 border-transparent'
            }),
            "placeholder": _vm.$t('flight.dob')
          },
          model: {
            value: _vm.formData.birthday,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "birthday", $$v);
            },
            expression: "formData.birthday"
          }
        })], 1), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "passenger-birthday",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "window",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }], null, false, 3998794529)
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('reservation[\'Passport\']'),
      "rules": "required|min:8|max:20",
      "vid": "passenger-passport"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Passport\']')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "passenger-passport",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('reservation[\'placeholderInput\']'),
            "lazy-formatter": "",
            "formatter": _vm.upperCaseFormatter
          },
          model: {
            value: _vm.formData.passport,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "passport", $$v);
            },
            expression: "formData.passport"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 1876287701)
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }